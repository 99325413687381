import React, {useRef} from "react";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Contact from "@components/Contact/Contact";
import useMouseCursor from "../hooks/useMouseCursor";
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  const contactRef = useRef(null);
  const sectionRef = useRef(null);
  const buttonRef = useRef(null);

  useMouseCursor(contactRef, sectionRef, 'cursor-white');
  useMouseCursor(contactRef, buttonRef, 'cursor-white');

  return (
    <>
      <Helmet>
        <title>Contact Gogel GmbH</title>
        <meta name="description" content="Contact Gogel GmbH to reach a great potential for your business" />
        <meta name="pagesummary" content="Company Official Name : Gogel GmbH Address: Marxergasse 7/8, 1030 Vienna" />
        <meta name="keywords" content="digital agency austria, Digital Marketing Spezialist, digital branding experience, B2B Content Marketing, SEO Agency Vienna, Digital marketing agency in Vienna," />
      </Helmet>
      <div className='fixed' ref={contactRef}/>
      <Header/>
      <Contact buttonRef={buttonRef}/>
      <Footer sectionRef={sectionRef}/>
    </>
  );
}

export default ContactPage;
