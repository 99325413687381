const en = {
  aboutUs: 'About us',
  services: 'Services',
  projects: 'Projects',
  clients: 'Clients',
  references: 'References',
  contact: 'Contact',
  copyright: 'Copyright',
  allRightsReserved: 'All rights reserved',
  privacyPolicy: 'Privacy policy',
  termsOfUse: 'Terms of use',
  orReachUsHere: 'Or reach us here',
  headOffice: 'Head office',
  socialNetwork: 'Social network',
  yourName: 'Your name',
  yourEmail: 'Your email',
  whatTypeOfWorkAreYouInterestedIn: 'What type of work are you interested in',
  tellUsYourIdea: 'Tell us your idea',
  send: 'Send',
  work: 'Work',
  viewAllProjects: 'View all projects',
  viewAllClients: 'View all clients',
  scroll: 'Scroll',
  viewProject: 'View project',
  letsTalk: 'Get In Touch',
  formSubmittedSuccessfully: 'Form submitted successfully',
  placeOrder: 'Request Cost Estimation',
  requestCostEstimation: 'Request cost estimation',
  yourInfo: 'Your Info',
  businessName: 'Business Name',
  yourPosition: 'Your Position',
  phone: 'Phone',
  whatServiceDoYouRequire: 'What service do you require',
  totalSummary: 'Total Cost Estimation',
  checkFormForErrors: 'Check form for errors',
  vatExcluded: "VAT is excluded from the prices displayed",
  companyName: 'Company Name',
  address: "Address",
  taxIdentificationNumber: 'Tax Identification Number',
  telephone: 'Telephone',
  contactPerson: 'Contact Person',
  errors: {
    notEmpty: "Cannot be empty",
    incorrectEmailFormat: "Email is not valid",
  }
};

export default en;
