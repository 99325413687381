import React, {useEffect, useState} from 'react';
import AOS from "aos";
import 'aos/dist/aos.css'
import {Link} from "react-router-dom";
import whiteLogo from "@assets/svg/whiteLogo.svg"
import {useTranslation} from "react-i18next";
import CMSAPI from "@utils/plugins/CMSAPI";

const Footer = ({sectionRef}) => {
  const { t } = useTranslation();
  const [socials, setSocials] = useState([]);
  const [files, setFiles] = useState();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  useEffect(async () => {
    CMSAPI.get(`/social-links?populate=deep`).then(response => {
      setSocials(response?.data?.data)
    })
  }, []);

  useEffect(async () => {
    CMSAPI.get(`/terms?populate=deep`).then(response => {
      setFiles(response?.data?.data?.attributes)
    })
  }, []);

  return (
    <div ref={sectionRef}
         className="w-full bg-[#000005] border-t-1 border-white border-opacity-40 font-inter bottom-0 left-0 right-0 px-5 xl:px-15 bg-transparent">
      <div
        className="flex flex-col lg:flex-row justify-betwenn items-center align-middle pt-5 lg:p-5 lg:py-10 xl:p-15 lg:text-white text-gray-400 text-opacity-90">
        <Link to="/">
          <img className="h-8 md:h-14" alt="logo" src={whiteLogo}/>
        </Link>
        <div className="flex flex-1 lg:justify-end 3xl:ml-60 flex-wrap sm:flex-nowrap text-xs md:text-sm lg:text-base uppercase xl:pl-15 py-5 lg:py-0 gap-x-10">
          <Link to="/about-us" className='transition-all duration-300 hover:font-bold hover:underline hover:cursor-pointer'>{t('aboutUs')}</Link>
          <Link to="/services" className='transition-all duration-300 hover:font-bold hover:underline hover:cursor-pointer'>{t('services')}</Link>
          <Link to="/projects" className='transition-all duration-300 hover:font-bold hover:underline hover:cursor-pointer'> {t('projects')}</Link>
          <Link to="/clients" className='transition-all duration-300 hover:font-bold hover:underline hover:cursor-pointer'> {t('clients')}</Link>
          <Link to="/contact" className='transition-all duration-300 hover:font-bold hover:underline hover:cursor-pointer'>{t('contact')}</Link>
          <Link to="/cost-estimation" className='transition-all duration-300 hover:font-bold hover:underline hover:cursor-pointer'>{t('placeOrder')}</Link>
        </div>
      </div>
      <div
        className="flex flex-col md:flex-row justify-between items-center text-gray-400 md:text-white text-xs lg:px-5 pt-2 pb-2 xl:px-15 font-montserrat tracking-wide border-t-1 border-opacity-30 border-white leading-7">
        <div className='flex flex-col md:flex-row font-sans'>
          <div className='flex flex-row'>
            <div>{t('copyright')} ©</div>
          <span className='uppercase mr-3'>Gogėl.</span>
          {t('allRightsReserved')}
          </div>
          <div className='flex flex-row'>
            <div className='md:ml-3 lg:ml-6 transition-all duration-300 hover:font-bold hover:underline hover:cursor-pointer' onClick={() => window.open(files?.privacyPolicy?.data[0]?.attributes?.url, '_blank')}>{t('privacyPolicy')}</div>
            <div className='ml-3 transition-all duration-300 hover:font-bold hover:underline hover:cursor-pointer' onClick={() => window.open(files?.termsAndConditions?.data[0]?.attributes?.url, '_blank')}>{t('termsOfUse')}</div>
          </div>
        </div>
        <div className="md:pt-3 lg:pt-0 md:pl-8 3xl:ml-auto">
          <div className="flex mt-3 justify-center mx-auto">
            {socials.map((social, index) => (
              <a
                href={social?.attributes?.url}
                key={index}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer p-2 mr-1 rounded-lg text-white hover:bg-dark-svg transition-bg duration-200"
              >
                <img src={social?.attributes?.icon?.data?.attributes?.url}  alt={social?.attributes?.url}/>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
