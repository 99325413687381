import React, {useEffect, useRef, useState} from "react";
import AboutUs from "@components/AboutUs/AboutUs";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import useMouseCursor from "../hooks/useMouseCursor";
import Team from "@components/AboutUs/Team";
import CMSAPI from "@utils/plugins/CMSAPI";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const AboutUsPage = () => {
  const {i18n} = useTranslation();

  const aboutUsRef = useRef(null);
  const sectionRef = useRef(null);
  const teamRef = useRef(null);
  useMouseCursor(aboutUsRef, sectionRef, 'cursor-white')
  useMouseCursor(aboutUsRef, teamRef, 'cursor-grow')

  const [data, setData] = useState({});

  useEffect(async () => {
    const language =
      i18n.language === 'al'
        ? 'sq'
        : i18n.language === 'de'
          ? 'de-AT'
          : i18n.language;
    ;
    CMSAPI.get(`/about-us?locale=${language}&populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setData(attributes)
    })
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <meta title="About Gogel GmbH"/>
        <meta name="description" content="Digital marketing agency in Vienna | Gogel GmbH"/>
        <meta name="pagesummary"
              content="Whether the goal is to build brand awareness or to increase considerations and purchases, we will ensure maximum efficiency of your media spend to deliver the strongest return on investment with the best possible results"/>
        <meta name="keywords"
              content="digital agency austria, Digital Marketing Spezialist, digital branding experience, B2B Content Marketing, SEO Agency Vienna, Digital marketing agency in Vienna,"/>
      </Helmet>
      <div ref={aboutUsRef} className='fixed'/>
      <Header/>
      <AboutUs title={data?.title} desccription1={data?.description1} description2={data?.description2}/>
      <Team sectionRef={teamRef} team={data?.employees}/>
      <Footer sectionRef={sectionRef}/>
    </>
  );
}

export default AboutUsPage;
