import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import '@assets/css/style.css';
import AOS from 'aos';
import 'aos/dist/aos.css'
import {isArrayEmpty} from "@utils/helpers";
import {useTranslation} from "react-i18next";
import {css} from "@emotion/css";
import HoverVideoPlayer from "react-hover-video-player";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const Project = ({ title, description, year, videoUrl, thumbnail, slug }) => {
  const [isHovered, setIsHovered] = useState(false);

  const history = useHistory();

  const hoverOverlayWrapperStyle = css`
    color: white;
    font-family: sans-serif;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: 1em;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h1,
    p {
      margin: 0;
    }

    a {
      color: white;
    }
  `;

  return (
    <div onClick={() => history.push(`/projects/${slug}`, "_blank")} className='mt-5 relative h-48 lg:h-50 3xl:h-60 border border-[#000002] border-opacity-20 rounded-md align-middle items-center shadow-sm'>
      <div className="aspect-w-16 aspect-h-9 overflow-hidden">
        <HoverVideoPlayer
          videoSrc={videoUrl}
          loadingOverlay={
            <div className="loading-overlay">
              <div className="loading-spinner" />
            </div>
          }
          hoverTargetRef={() => {}}
          pausedOverlay={
            <img
              src={thumbnail}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          }
          paused={!isHovered}
          onHoverOff={() => setIsHovered(false)}
          onHoverOn={() => setIsHovered(true)}
          thumbnailMode={"none"}
          hoverOverlayWrapperClassName={hoverOverlayWrapperStyle}
          hoverOverlay={
            <div className='flex flex-col'>
              <div className='w-full text-white text-xl lg:text-3xl font-sans tracking-wider font-semibold font-inter'>
                {title}
              </div>
              <div className='grid grid-cols-2 w-full text-sm lg:text-base'>
                <div className='col-span-1 text-white font-inter font-light text-opacity-80'>
                  {description}
                </div>
                <div className='col-span-1 text-white font-inter font-light text-opacity-80 text-right'>
                  {year}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

const OurProjects = ({projects = [], title}) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full">
      <div className="flex max-w-[1700px] flex-col justify-center mx-auto pt-10">
        <div
          className="flex w-full justify-center items-center">
          <div id='approachSection' className="w-full flex flex-col items-center xl:w-4/5 [@media(min-width:1510px)]:w-3/4 2xl:my-10">
            <div className="flex justify-between w-full items-center align-middle">
              {title ? <div
                className='mt-2 text-left text-3xl lg:text-4xl lg:text-5xl font-semibold'
                data-aos="fade-up" data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500"
                data-aos-once="true">{title}
              </div> : <SkeletonLoader count={1}/> }
              <div
                className='underline font-thin hover:cursor-pointer text-sm md:text-base mt-3.5 md:mt-6'
                data-aos="fade-up"
                data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
                <Link to='/projects'>
                  {t('viewAllProjects')}
                </Link>
              </div>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-x-6 lg:gap-x-12 mt-6 lg:mt-12'>
              {!isArrayEmpty(projects) && projects.map((project) => {
                return <Project
                  title={project?.title}
                  videoUrl={project?.video?.data?.attributes?.url}
                  description={project?.description}
                  year={project?.year}
                  thumbnail={project?.thumbnail?.data?.attributes?.url}
                  slug={project?.slug}
                />
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurProjects;

