import React from 'react';
import {Switch, BrowserRouter as Router, Redirect, Route} from 'react-router-dom';
import ScrollToTop from '@router/ScrollToTop';
import HomePage from '@pages/HomePage';
import AboutUsPage from "@pages/AboutUsPage";
import ServicesPage from "@pages/ServicesPage";
import ProjectsPage from "@pages/ProjectsPage";
import ContactPage from "@pages/ContactPage";
import ClientsPage from "@pages/ClientsPage";
import ProjectPage from "@pages/ProjectPage";
import OrderPage from "@pages/OrderPage";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={HomePage}/>
        <Route exact path='/about-us' component={AboutUsPage}/>
        <Route exact path="/projects" component={ProjectsPage}/>
        <Route exact path="/projects/:slug" component={ProjectPage}/>
        <Route exact path="/services" component={ServicesPage}/>
        <Route exact path="/clients" component={ClientsPage}/>
        <Route exact path="/contact" component={ContactPage}/>
        <Route exact path="/cost-estimation" component={OrderPage}/>
        <Redirect from="*" to="/"/>
      </Switch>
    </Router>
  );
};

export default Routes;
