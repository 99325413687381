import React, {useEffect, useRef, useState} from "react";
import Header from "@hoc/partials/Header";
import HomeHeader from "@components/Home/HomeHeader";
import OurProjects from "@components/Home/OurProjects";
import Footer from "@hoc/partials/Footer";
import "@assets/css/style.css"
import useMouseCursor from "../hooks/useMouseCursor";
import CMSAPI from "@utils/plugins/CMSAPI";
import OurClients from "@components/Home/OurClients";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const HomePage = () => {
  const { i18n } = useTranslation();

  const homePageRef = useRef(null);
  const sectionRef = useRef(null);
  useMouseCursor(homePageRef, sectionRef, 'cursor-white')

  const [data, setData] = useState({});

  useEffect(async () => {
    const language =
      i18n.language === 'al'
        ? 'sq'
        : i18n.language === 'de'
          ? 'de-AT'
          : i18n.language;
    CMSAPI.get(`/homepage?locale=${language}&populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setData(attributes)
    })
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <meta title="Digital Advertising Agency | Gogel GmbH"/>
        <meta name="description" content="Digital marketing agency in Vienna | Gogel GmbH"/>
        <meta name="pagesummary" content="Whether the goal is to build brand awareness or to increase considerations and purchases, we will ensure maximum efficiency of your media spend to deliver the strongest return on investment with the best possible results"/>
        <meta name="keywords" content="digital agency austria, Digital Marketing Spezialist, digital branding experience, B2B Content Marketing, SEO Agency Vienna, Digital marketing agency in Vienna,"/>
      </Helmet>
      <div ref={homePageRef} className='fixed'/>
      <Header/>
      <div className='px-3 lg:px-0'>
        <HomeHeader videos={data?.videoCarousel?.videos.map((video) => video.file.data.attributes.url)} title={data?.videoCarousel?.title} description={data?.videoCarousel?.description}/>
        <OurProjects title={data?.workSection?.title} projects={data?.workSection?.projects}/>
        <OurClients title={data?.clientsSection?.title} clients={data?.clientsSection?.clients}/>
      </div>
      <Footer sectionRef={sectionRef}/>
    </>
  );
}

export default HomePage;