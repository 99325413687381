import React, {useEffect, useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import SkeletonLoader from "@core/loaders/SkeletonLoader";
import {useTranslation} from "react-i18next";
import {FaChevronRight} from "react-icons/all";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import CMSAPI from "@utils/plugins/CMSAPI";

const Header = ({title, subtitle, description}) => {
  return <div className="grid grid-cols-1 md:grid-cols-2 mt-2.5 px-3 md:px-12 xl:px-28">
    <div className="flex flex-col xl:ml-10 3xl:ml-22 md:px-0">
      <div className="tracking-widest text-4xl lg:text-7xl font-semibold uppercase justify-center align-middle"
           data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
           data-aos-once="true">{title ? title : <SkeletonLoader count={1}/>}
      </div>
      <div className="flex mt-3  text-sm uppercase tracking-wide justify-start px-1">
        <div data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in"
             data-aos-duration="500" data-aos-once="true">{subtitle ? subtitle : <SkeletonLoader count={1}/>}
        </div>
      </div>
    </div>
    <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
         data-aos-once="true"
         className='z-30 flex flex-col mt-2 text-base lg:text-lg font-thin w-full xl:w-5/6'>
      {description ? description : <SkeletonLoader count={5}/>}
    </div>
  </div>
}

const RowImages = ({image1, image2}) => {
  return <div className="z-30 grid grid-cols-1 md:grid-cols-2 mt-2.5 px-3 md:px-12 xl:px-28 pt-2 xl:pt-8">
    <img data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
         data-aos-once="true"
         src={image1} alt="img"
         className='xl:ml-10 3xl:ml-22 w-full lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[600px] rounded-lg'/>
    <img data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
         data-aos-once="true"
         src={image2} alt="img"
         className='mt-5 md:mt-0 w-full lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[600px] rounded-lg'/>
  </div>
}

const FullImage = ({ image }) => {
  return (
    <div className="grid grid-cols-1 mt-2.5 px-3 md:px-12 xl:px-28">
      <img
        data-aos="fade-up"
        data-aos-offset="150"
        data-aos-easing="ease-in"
        data-aos-duration="500"
        data-aos-once="true"
        src={image}
        alt="img"
        className="h-auto md:object-cover lg:object-cover xl:object-cover 3xl:object-cover lg:mt-12 rounded-lg w-full xl:ml-10 3xl:ml-22 md:w-[650px] lg:w-[900px] xl:w-[1070px] 3xl:w-[1270px] mt-12"
      />
    </div>
  );
};

const RowText = ({firstParagraph, secondParagraph}) => {
  return <div className="grid grid-cols-1 md:grid-cols-2 lg:mt-8 px-3 md:px-12 xl:px-28 pt-2 md:pt-8">
    <div className='md:ml-10 3xl:ml-22 '>
    <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
         data-aos-once="true"
         className='mt-5 md:mt-0  font-thin text-base lg:text-lg leading-tight md:text-justify w-full self-center w-full md:w-5/6'>
      {firstParagraph ? firstParagraph : <SkeletonLoader count={5}/>}
    </div>
    </div>
    <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
         data-aos-once="true"
         className='mt-5 md:mt-0  font-thin text-base lg:text-lg leading-tight md:text-justify w-full self-center w-full md:w-5/6'>
      {secondParagraph ? secondParagraph : <SkeletonLoader count={5}/>}
    </div>
  </div>
}

const FullText = ({description}) => {
  return <div className="grid grid-cols-1 lg:mt-8 px-3 md:px-12 xl:px-28 pt-2 md:pt-8">
    <div className='md:ml-10 3xl:ml-22 '>
      <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
           data-aos-once="true"
           className='mt-5 md:mt-0  font-thin text-base lg:text-lg leading-tight md:text-justify w-full self-center w-full md:w-5/6'>
        {description ? description : <SkeletonLoader count={5}/>}
      </div>
    </div>
  </div>
}

const RowImageText = ({description, image}) => {
  return <div className="z-30 grid grid-cols-1 md:grid-cols-2 mt-2.5 px-3 md:px-12 xl:px-28 pt-2 xl:pt-8 items-center align-middle">
    <img data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
         data-aos-once="true"
         src={image} alt="img"
         className='xl:ml-10 3xl:ml-22 w-full lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[600px] rounded-lg'/>
    <div className='md:ml-10 3xl:ml-22 '>
      <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
           data-aos-once="true"
           className='mt-5 md:mt-0  font-thin text-base lg:text-lg leading-tight md:text-justify w-full self-center w-full md:w-5/6'>
        {description ? description : <SkeletonLoader count={5}/>}
      </div>
    </div>
  </div>
}

const AllFields = ({description1, image1, description2, image2}) => {
  return <div className="z-30 grid grid-cols-1 md:grid-cols-2 mt-2.5 px-3 md:px-12 xl:px-28 pt-2 xl:pt-8 gap-y-8 items-center align-middle">
    <img data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
         data-aos-once="true"
         src={image1} alt="img"
         className='xl:ml-10 3xl:ml-22 w-[300px] lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[600px] rounded-lg'/>
    <div className='md:ml-10 3xl:ml-22 '>
      <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
           data-aos-once="true"
           className='mt-5 md:mt-0  font-thin text-base lg:text-lg leading-tight md:text-justify w-full self-center w-full md:w-5/6'>
        {description1 ? description1 : <SkeletonLoader count={5}/>}
      </div>
    </div>
    <div className='md:ml-10 3xl:ml-22'>
      <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
           data-aos-once="true"
           className='mt-5 md:mt-0  font-thin text-base lg:text-lg leading-tight md:text-justify w-full self-center w-full md:w-5/6'>
        {description2 ? description2 : <SkeletonLoader count={5}/>}
      </div>
    </div>
    <img data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
         data-aos-once="true"
         src={image2} alt="img"
         className='xl:ml-10 3xl:ml-22 w-[300px] lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[600px] rounded-lg'/>
  </div>
}



const NextProject = ({ extraClasses, title, description, subtitle, img, slug}) => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div
      className={`py-6 px-2 md:p-6 lg:px-10 align-middle items-center text-black ${extraClasses}`}
    >
      <div className="flex flex-col lg:flex-row items-center align-middle">
        <div>
          <img
            src={img}
            alt={title}
            className="w-120 h-40 object-cover"
          />
        </div>
        <div className="mt-5 lg:mt-0 lg:ml-7 flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <div
              className="text-base lg:text-xl tracking-wide font-semibold"
              data-aos="fade-right"
              data-aos-offset="50"
              data-aos-easing="ease-in"
              data-aos-duration="500"
              data-aos-once="true"
            >
              {title}
            </div>
            <div
              className="text-sm lg:text-lg tracking-wide font-light uppercase"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-easing="ease-in"
              data-aos-duration="500"
              data-aos-once="true"
            >
              {subtitle}
            </div>
          </div>
          <div className="flex flex-col w-full text-xs lg:text-base font-light line-clamp-3 mt-3">
            {description}
          </div>
          <div
            onClick={() => history.push(`/projects/${slug}`)}
            data-aos="fade-right"
            data-aos-offset="50"
            data-aos-easing="ease-in"
            data-aos-duration="500"
            data-aos-once="true"
            className="flex mt-3 font-inter align-middle items-center group font-normal hover:cursor-pointer"
          >
            <div className="text-sm lg:text-lg underline group-hover:translate-x-2 transform ease-in duration-300">
              {t('viewProject')}
            </div>
            <FaChevronRight className="text-sm ml-1 group-hover:translate-x-2 transform ease-in duration-300" />
          </div>
        </div>
      </div>
    </div>
  );
};

const Project = ({data = []}) => {
  const { i18n } = useTranslation();
  const { slug } = useParams();

  const [previewProjects, setPreviewProjects] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  useEffect(async () => {
    const language =
      i18n.language === 'al'
        ? 'sq'
        : i18n.language === 'de'
          ? 'de-AT'
          : i18n.language;
    CMSAPI.get(`/projects?locale=${language}&populate=deep&filters[slug][$ne]=${slug}&pagination[pageSize]=2`).then(response => {
      setPreviewProjects(response.data.data)
    })
  }, [i18n.language, slug]);

  if (!data || !data.sections) {
    return null;
  }

  return (
    <div className="w-full h-full ">
      <div className="flex pt-20 mx-3 3xl:mx-auto max-w-[1700px] flex-col">
        <div className="flex py-6 3xl:mx-auto max-w-[1700px] flex-col">
          <Header title={data?.title} description={data?.description} subtitle={data?.subtitle}/>
          {data?.sections.map((section) => {
              if (section.image1.data !==null && section.image2.data === null && section?.description1 === null && section?.description2 === null) {
                return <FullImage key={section.id} image={section?.image1?.data?.attributes?.url} />;
              } else if (section.image1.data === null && section.image2.data !==null && section?.description1 === null && section?.description2 === null) {
                return <FullImage key={section.id} image={section?.image2.data?.attributes?.url} />;
              } else if (section.image1.data !== null && section.image2.data !== null && section?.description1 === null && section?.description2 === null) {
                return <RowImages key={section.id} image1={section?.image1.data?.attributes?.url} image2={section?.image2.data?.attributes?.url}/>;
              } else if (section.image1.data !== null && section.image2.data === null && section?.description1 !== null && section?.description2 === null) {
                return <RowImageText key={section.id} image={section?.image1.data?.attributes?.url} description={section?.description1}/>;
              } else if (section.image1.data !== null && section.image2.data === null && section?.description1 === null && section?.description2 !== null) {
                return <RowImageText key={section.id} image={section?.image1.data?.attributes?.url} description={section?.description2}/>;
              } else if (section.image1.data === null && section.image2.data !== null && section?.description1 !== null && section?.description2 === null) {
                return <RowImageText key={section.id} image={section?.image2.data?.attributes?.url} description={section?.description1}/>;
              } else if (section.image1.data === null && section.image2.data !== null && section?.description1 === null && section?.description2 !== null) {
                return <RowImageText key={section.id} image={section?.image2.data?.attributes?.url} description={section?.description2}/>;
              } else if (section.image1.data === null && section.image2.data === null && section?.description1 !== null && section?.description2 !== null) {
                return <RowText firstParagraph={section?.description1} secondParagraph={section?.description2}/>;
              } else if (section.image1.data === null && section.image2.data === null && section?.description1 !== null && section?.description2 !== null) {
                return <RowText firstParagraph={section?.description1} secondParagraph={section?.description2}/>;
              } else if (section.image1.data === null && section.image2.data === null && section?.description1 !== null && section?.description2 !== null) {
                return <RowText firstParagraph={section?.description1} secondParagraph={section?.description2}/>;
              } else if (section.image1.data === null && section.image2.data === null && section?.description1 !== null && section?.description2 === null) {
                return <FullText description={section?.description1}/>;
              } else if (section.image1.data === null && section.image2.data === null && section?.description1 === null && section?.description2 !== null) {
                return <FullText description={section?.description2}/>;
              }
              else {
                return <AllFields image1={section?.image1.data?.attributes?.url} image2={section?.image2.data?.attributes?.url} description1={section?.description1} description2={section?.description2}/>
              }
          })}
          <div
            className="flex flex-col justify-between items-center md:px-12 xl:px-28 my-5 font-montserrat tracking-wide border-t-1 border-opacity-20 border-black leading-7">
            {previewProjects.length && previewProjects.map((preview) => (
              <NextProject
                img={preview?.attributes?.thumbnail?.data?.attributes?.url}
                title={preview?.attributes?.title}
                description={preview?.attributes?.description}
                subtitle={preview?.attributes?.subtitle}
                slug={preview?.attributes?.slug}
              />))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project