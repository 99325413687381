import {notify} from 'react-notify-toast';
import moment from "moment";

export const showSuccess = (message = 'Success', timeout = 4000) => {
  notify.show(message, 'custom', timeout, {
    background: '#10b981',
    text: 'white',
  });
};
export const showCopiedFlash = (message = 'Success', timeout = 800) => {
  notify.show(message, 'custom', timeout, {
    background: '#EC7F00',
    text: 'white',
  });
};

export const showError = (error, timeout = 4000) => {
  notify.show(error, 'custom', timeout, {
    background: '#ef4444',
    text: 'white',
  });
};

export const showInfo = (message, timeout = 2000) => {
  notify.show(message, 'custom', timeout, {
    background: '#3b82f6',
    text: 'white',
  });
};

export const formatAmount = (number) => {
  return number?.toLocaleString("en-US");
}

export const truncateText = (text, length = 100) => {
  if (length > text.length) {
    return text
  }
  return `${text.substring(0, length)}...`
}

export const resizeImageHeight = (width) => {
  if (!width) {
    return 0
  }
  return width * (9 / 16)
}

export const dateFormatter = (date = new Date(), format = 'DD/mm/YYYY') => {
  return moment(date).format(format);
}

export const isArrayEmpty = (array) => array.length === 0;

export const numbersOnly = (value) => {
  return value.replace(/[^0-9+]/g, '');
};

