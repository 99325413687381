import React from 'react';
import App from '@core/App'
import ReactDOM from 'react-dom';
import '@assets/css/tailwind.css';
import '@assets/css/index.css';
import reportWebVitals from './reportWebVitals';
import '@i18n/index';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
