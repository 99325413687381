const al = {
  aboutUs: 'Rreth nesh',
  services: 'Shërbimet',
  references: 'Referencat',
  projects: 'Projektet',
  clients: 'Klientët',
  contact: 'Kontaktoni',
  copyright: 'Copyright',
  allRightsReserved: 'Të gjitha të drejtat e rezervuara',
  privacyPolicy: 'Politika e privatësisë',
  termsOfUse: 'Kushtet e përdorimit',
  orReachUsHere: 'Ose na kontaktoni këtu',
  headOffice: 'Zyra qendrore',
  socialNetwork: 'Rrjetet sociale',
  yourName: 'Emri juaj',
  yourEmail: 'Email-i juaj',
  whatTypeOfWorkAreYouInterestedIn: 'Për çfarë lloj pune jeni të interesuar',
  tellUsYourIdea: 'Na tregoni idenë tuaj',
  send: 'Dërgo',
  work: 'Puna',
  viewAllProjects: 'Shikoni të gjitha projektet',
  viewAllClients: 'Shikoni të gjithë klientët',
  scroll: 'Lëvizni',
  viewProject: 'Shikoni projektin',
  letsTalk: 'Lass uns reden',
  formSubmittedSuccessfully: 'Formular erfolgreich eingereicht',
  errors: {
    notEmpty: "Nuk mund të jetë bosh",
    incorrectEmailFormat: "Email-i nuk është i vlefshëm",
  }
};
export default al;
