import React, { useState, useEffect } from 'react';
import '@assets/css/style.css';
import ReactPlayer from 'react-player';
import {BiChevronDown} from "react-icons/all";
import AOS from "aos";
import { Link } from 'react-scroll';
import {useTranslation} from "react-i18next";
import SkeletonLoader from "@core/loaders/SkeletonLoader";
const HomeHeader = ({videos = [], title, description}) => {
  const { t } = useTranslation();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const handleVideoEnded = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full items-center self-center justify-center relative">
      <div className="flex flex-col items-center self-center justify-center">
        <div
          style={{
            width: '100vw',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ReactPlayer
            playsinline
            scrollableAncestor='window'
            url={videos[currentVideoIndex]}
            playing={true}
            loop={false}
            onEnded={handleVideoEnded}
            width="100%"
            height="100%"
            controls={false}
            muted={true}
          />
          <div className='left-3 right-3 lg:left-10 lg:right-10 bottom-1 lg:bottom-5 lg:p-5'
            style={{
              position: 'absolute',
              color: 'black',
              fontSize: '41px',
              fontWeight: 700,
              lineHeight: '48px',
              letterSpacing: '0em',
              textAlign: 'left',
            }}
          >
            {title ? <div
              className="w-full text-gray-900 lg:w-1/2 text-sm lg:text-4xl leading-4 font-sans"
              data-aos="fade-right"
              data-aos-offset="80"
              data-aos-easing="ease-in"
              data-aos-duration="500"
              data-aos-once="true"
            >
              {title}
            </div> : <SkeletonLoader count={3}/>
            }
            {description ? <div className="hidden lg:flex flex-row justify-between">
              <div
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in"
                data-aos-duration="700"
                data-aos-once="true"
                className="text-xs lg:text-lg underline leading-6 pt-1 lg:pt-5 font-sans hover:cursor-pointer"
              >
                {description}
              </div>
              <Link
                to="approachSection"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <div
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in"
                  data-aos-duration="700"
                  data-aos-once="true"
                  className="hover:underline flex flex-row items-center align-middle text-xs lg:text-lg leading-6 pt-1 lg:pt-5 font-sans hover:cursor-pointer"
                >
                  {t('scroll')} <BiChevronDown className="ml-2" />
                </div>
              </Link>
            </div> :
            <SkeletonLoader count={1}/>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
