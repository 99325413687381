import React from "react";

const CInput = ({
                    id,
                    placeholder,
                    handleInputChange,
                    error,
                    onFocus,
                    value,
                    disabled,
                    required,
                    border = 'border-b',
    padding = 'px-2 py-3',
                    ...props
                }) => {
    return (
        <div {...props}>
            <div
                className={`w-full ${
                    error
                        ? "text-red-500 text-opacity-100"
                        : "focus-within:text-brand-light-blue text-opacity-40 focus-within:text-opacity-100"
                }`}
            >
                <input
                    id={id}
                    value={value}
                    autoComplete="off"
                    className={`${border} ${padding} text-base font-thin lg:text-lg leading-tight placeholder-black
          placeholder-opacity-90 placeholder-font-thin placeholder-text-base rounded-sm appearance-none bg-transparent w-full focus:outline-none mt-1 
          ${error ? "border-red-700" : "border-black focus:border-brand-light-blue"}`}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onClick={onFocus}
                    type="text"
                    placeholder={placeholder}
                    disabled={disabled}
                />
                {error && <div
                    className={`text-xs text-red-500 pt-2 text-left ${error ? '' : 'pb-4'}`}>{error ? error : ''}</div>}
            </div>
        </div>
    );
};

export default CInput;