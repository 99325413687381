import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import socials from "@utils/data/socials";
import CMSAPI from "@utils/plugins/CMSAPI";
import SkeletonLoader from "@core/loaders/SkeletonLoader";
const ContactDetails = () => {
  const {t} = useTranslation();
  const [data, setData] = useState();
  const [socials, setSocials] = useState([]);

  useEffect(async () => {
    CMSAPI.get(`/contact-us?populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setData(attributes)
    })
    CMSAPI.get(`/social-links?populate=deep`).then(response => {
      setSocials(response?.data?.data)
    })
  }, []);

  return (
    <div className="flex flex-col lg:w-2/3 lg:ml-40 text-left lg:pt-16 text-lg">
      <div className="font-semibold tracking-wide text-gray-700 text-opacity-70 w-full text-xl pb-2">{t('orReachUsHere')}</div>
      <div className="font-thin text-base font-inter tracking-wide w-full underline py-1 hover:cursor-pointer hover:translate-y-1 transform ease-in duration-300"
           onClick={() => {window.open("mailto:" + data?.email)}}>{data?.email ? data?.email : <SkeletonLoader count={1}/> }</div>
      <div className='font-thin text-base font-inter tracking-wide w-full underline py-1'>{data?.phoneNumber ? data?.phoneNumber : <SkeletonLoader count={1}/> }</div>
      <div className="font-thin tracking-wide text-gray-700 text-opacity-70 w-full pt-4 lg:pt-7 text-xl pb-2">{t('headOffice')}</div>
      <div className="font-normal text-base font-inter tracking-wide w-full py-1">{data?.address ? data?.address : <SkeletonLoader count={1}/>}</div>
      <div className="font-thin tracking-wide text-gray-700 text-opacity-70 w-full pt-4 lg:pt-7 text-xl pb-2">{t('socialNetwork')}</div>
      {socials.map((social, index) => (
        <a
          href={social?.attributes?.url}
          key={index}
          target="_blank"
          rel="noreferrer"
          className="underline hover:cursor-pointer font-thin text-base font-inter tracking-wide w-full py-1 hover:translate-y-1 transform ease-in duration-300"
        >
          <div>{social?.attributes?.name}</div>
        </a>
      ))}
    </div>
  );
}

export default ContactDetails;
