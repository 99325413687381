import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import '@assets/css/style.css';
import AOS from 'aos';
import 'aos/dist/aos.css'
import {isArrayEmpty} from "@utils/helpers";
import {useTranslation} from "react-i18next";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const Client = ({ img, url }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onClick={() => window.open(url, "_blank")}
      className={`hover:cursor-pointer border-0.5 border-[#000005] border-opacity-20 align-middle items-center relative ${
        isHovered ? 'hovered' : ''
      }`}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <img
        src={img}
        alt="Client Logo"
        className={`inset-0 w-full h-full ${isHovered ? 'desaturate' : ''}`}
        style={{ objectFit: 'contain' }}
      />
    </div>
  );
};

const OurClients = ({clients = [], title}) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full pt-10 lg:pt-0 pb-10">
      <div className="flex max-w-[1700px] flex-col justify-center mx-auto">
        <div
          className="flex w-full justify-center items-center lg:pb-18 md:pb-24">
          <div className="w-full flex flex-col items-center xl:w-4/5 [@media(min-width:1510px)]:w-3/4 2xl:my-10">
            <div className="flex justify-between w-full items-center align-middle">
              {title ? <div
                className='mt-2 text-left text-3xl lg:text-4xl lg:text-5xl font-semibold'
                data-aos="fade-up" data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500"
                data-aos-once="true">{title}
              </div> : <SkeletonLoader count={1} />}
              <div
                className='underline font-thin hover:cursor-pointer text-sm md:text-base mt-3.5 md:mt-6'
                data-aos="fade-up"
                data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
                <Link to='/clients'>
                  {t('viewAllClients')}
                </Link>
              </div>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-4 mt-6 lg:mt-12'>
              {!isArrayEmpty(clients) && clients.map((client, index) => (
                  <Client key={index} img={client?.image?.data?.attributes?.url} url={client?.redirectUrl}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurClients;

