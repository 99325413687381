import { useEffect } from "react";

const useMouseCursor = (sectionRef = {}, containerRef = null, mouseClass = 'cursor-grow') => {
  const setHover = () => {
    if (containerRef && containerRef.current) {
      sectionRef.current.classList.add(mouseClass);
      sectionRef.current.classList.remove('cursor');
      document.body.style.cursor = 'none';
    }
  }

  const removeHover = () => {
    if (containerRef && containerRef.current) {
      sectionRef.current.classList.remove(mouseClass);
      sectionRef.current.classList.add('cursor');
      document.body.style.cursor = 'default';
    }
  }

  useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      if (!sectionRef.current) return
      const { clientX, clientY } = ev
      setTimeout(() => {
        sectionRef.current.style.setProperty('--x', `${clientY}px`)
        sectionRef.current.style.setProperty('--y', `${clientX}px`)
        sectionRef.current.classList.add('cursor')
      }, 60)
    }

    window.addEventListener('mousemove', updateMousePosition)
    if(containerRef && containerRef.current) {
      containerRef.current.addEventListener('mouseenter', setHover)
      containerRef.current.addEventListener('mouseleave', removeHover)
    }
    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
      if(containerRef) {
        containerRef.current.removeEventListener('mouseenter', setHover)
        containerRef.current.removeEventListener('mouseleave', removeHover)
      }
    }
  }, [])
}

export default useMouseCursor;
