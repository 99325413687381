import React, {useEffect, useRef, useState} from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Projects from "@components/Projects/Projects";
import useMouseCursor from "../hooks/useMouseCursor";
import ProjectsHeader from "@components/Projects/Header"
import CMSAPI from "@utils/plugins/CMSAPI";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const ProjectsPage = () => {
  const {i18n} = useTranslation();
  const projectsRef = useRef(null);
  const sectionRef = useRef(null);
  useMouseCursor(projectsRef, sectionRef, 'cursor-white');

  const [data, setData] = useState([])

  useEffect(async () => {
    const language =
      i18n.language === 'al'
        ? 'sq'
        : i18n.language === 'de'
          ? 'de-AT'
          : i18n.language;
    CMSAPI.get(`/our-projects?locale=${language}&populate=deep`).then(response => {
      setData(response.data.data?.attributes)
    })
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <meta title="Projects of Gogel GmbH"/>
        <meta name="description" content="Check our case studies, clients working with us since the begining"/>
        <meta name="pagesummary" content="In a market often veiled in disarrayed pricing, Gogel distinguishes itself with transparent fixed-price packages, highlighting Digital Advertising as its fundamental service.
"/>
        <meta name="keywords"
              content="digital agency austria, Digital Marketing Spezialist, digital branding experience, B2B Content Marketing, SEO Agency Vienna, Digital marketing agency in Vienna,"/>
      </Helmet>
      <div ref={projectsRef} className='fixed'/>
      <Header/>
      <ProjectsHeader title={data?.title} description={data?.description}/>
      <Projects projects={data?.projects}/>
      <Footer sectionRef={sectionRef}/>
    </>
  );
};

export default ProjectsPage;
