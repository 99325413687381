const de = {
  aboutUs: 'Über uns',
  services: 'Dienstleistungen',
  references: 'Referenzen',
  contact: 'Kontakt',
  projects: 'Projekte',
  clients: 'Kunden',
  copyright: 'Copyright',
  allRightsReserved: 'Alle Rechte vorbehalten',
  privacyPolicy: 'Datenschutzrichtlinie',
  termsOfUse: 'Nutzungsbedingungen',
  orReachUsHere: 'Oder erreichen Sie uns hier',
  headOffice: 'Hauptsitz',
  socialNetwork: 'Soziales Netzwerk',
  yourName: 'Ihr Name',
  yourEmail: 'Deine E-Mail',
  whatTypeOfWorkAreYouInterestedIn: 'Für welche Art von Arbeit interessieren Sie sich?',
  tellUsYourIdea: 'Teilen Sie uns Ihre Idee mit',
  send: 'Schicken',
  work: 'Arbeiten',
  viewAllProjects: 'Alle Projekte ansehen',
  viewAllClients: 'Alle Kunden anzeigen',
  scroll: 'Scrollen',
  viewProject: 'Projekt ansehen',
  letsTalk: 'Lass uns reden',
  formSubmittedSuccessfully: 'Formular erfolgreich eingereicht',
  placeOrder: 'Preis anfordern',
  requestCostEstimation: 'Kostenvoranschlag anfordern',
  yourInfo: 'Ihre Infos',
  businessName: 'Firmenname',
  yourPosition: 'Dein Standpunkt',
  phone: 'Telefon',
  whatServiceDoYouRequire: 'Welchen Service benötigen Sie',
  totalSummary: 'Gesamtkostenschätzung',
  vatExcluded: "In den angezeigten Preisen ist die Mehrwertsteuer nicht enthalten",
  checkFormForErrors: 'Überprüfen Sie das Formular auf Fehler',
  companyName: 'Name der Firma',
  address: "Adresse",
  taxIdentificationNumber: 'Steueridentifikationsnummer',
  telephone: 'Telefon',
  contactPerson: 'Ansprechpartner',
  errors: {
    notEmpty: "Kann nicht leer sein",
    incorrectEmailFormat: 'Email ist ungültig',
  }
};
export default de;
