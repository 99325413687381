import React, { useState } from 'react';
import {css} from "@emotion/css";
import HoverVideoPlayer from "react-hover-video-player";
import {isArrayEmpty} from "@utils/helpers";
import {useHistory} from "react-router-dom";
const Project = ({ title, description, year, videoUrl, thumbnail, slug }) => {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const hoverOverlayWrapperStyle = css`
    color: white;
    font-family: sans-serif;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: 1em;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h1,
    p {
      margin: 0;
    }

    a {
      color: white;
    }
  `;

  return (
    <div onClick={() => history.push(`/projects/${slug}`, "_blank")} className='mt-5 relative lg:h-82 3xl:h-76 border border-[#000002] border-opacity-20 rounded-md align-middle items-center shadow-sm'>
      <div className="aspect-w-16 aspect-h-9 overflow-hidden">
        <HoverVideoPlayer
          videoSrc={videoUrl}
          loadingOverlay={
            <div className="loading-overlay">
              <div className="loading-spinner" />
            </div>
          }
          hoverTargetRef={() => {}}
          pausedOverlay={
            <img
              src={thumbnail}
              alt=""
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          }
          paused={!isHovered}
          onHoverOff={() => setIsHovered(false)}
          onHoverOn={() => setIsHovered(true)}
          thumbnailMode={"none"}
          hoverOverlayWrapperClassName={hoverOverlayWrapperStyle}
          hoverOverlay={
            <div className='flex flex-col'>
              <div className='w-full text-white text-xl lg:text-3xl font-sans tracking-wider font-semibold font-inter'>
                {title}
              </div>
              <div className='grid grid-cols-2 w-full text-sm lg:text-base'>
                <div className='col-span-1 text-white font-inter font-light text-opacity-80'>
                  {description}
                </div>
                <div className='col-span-1 text-white font-inter font-light text-opacity-80 text-right'>
                  {year}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

const Projects = ({projects = []}) => {
  return (
    <div className="w-full h-full">
      <div className='pt-6 lg:pt-14 pb-20 3xl:mx-auto max-w-[1700px] flex-col mx-5 lg:mx-10 xl:mx-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 3xl:grid-cols-3 lg:gap-14">
          {!isArrayEmpty(projects) && projects.map((project) => {
            return <Project
              description={project?.description}
              title={project?.title}
              videoUrl={project?.video?.data?.attributes?.url}
              year={project?.year}
              thumbnail={project?.thumbnail?.data?.attributes?.url}
              slug={project?.slug}
            />
          })}
        </div>
      </div>
    </div>
  );
}

export default Projects;