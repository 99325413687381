import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import alTranslations from "./al";
import enTranslations from "./en";
import deTranslations from "./de";

if (localStorage.getItem('GogelWebLang') !== ('en' && 'al' && 'de')) {
  localStorage.setItem('GogelWebLang', 'de')
}

const language = localStorage.getItem('GogelWebLang')

const resources = {
  en: {
    translation: enTranslations
  },
  al: {
    translation: alTranslations
  },
  de: {
    translation: deTranslations
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
