import React, {useEffect, useMemo, useRef, useState} from "react";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import "@assets/css/style.css"
import useMouseCursor from "../hooks/useMouseCursor";
import CMSAPI from "@utils/plugins/CMSAPI";
import VideoHeader from "@components/Order/VideoHeader";
import {useTranslation} from "react-i18next";
import OrderForm from "@components/Order/OrderForm";
import useFormErrors from "../hooks/useFormErrors";
import Packages from "@components/Order/Packages";
import CInput from "@core/inputs/Input";
import {showError, showSuccess} from "@utils/helpers";
import PackageValidator from "@utils/validators/PackageValidator";
import {Helmet} from "react-helmet";

const OrderPage = () => {
  const {i18n, t} = useTranslation();

  const orderPageRef = useRef(null);
  const sectionRef = useRef(null);
  useMouseCursor(orderPageRef, sectionRef, 'cursor-black')

  const {clearError, getError, validateErrors} = useFormErrors();

  const [data, setData] = useState({});

  const [businessName, setBusinessName] = useState('');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [selectedSubPackages, setSelectedSubPackages] = useState([]);


  useEffect(async () => {
    CMSAPI.get(`/order-page?populate=deep`).then(response => {
      setData(response?.data?.data?.attributes?.video?.data?.attributes?.url)
    })
  }, []);

  const total = useMemo(() => {
    return selectedSubPackages.reduce((acc, curr) => acc + curr.attributes.price, 0);
  }, [selectedSubPackages]);


  const onSubmit = () => {
    const errors = validateErrors(
      {businessName, name, position, email, phone, packages: selectedSubPackages},
      PackageValidator
    );
    if (errors) {
      showError(t('checkFormForErrors'))
      return;
    }
    CMSAPI.post("/package-orders/create", {
      BusinessName: businessName,
      Name: name,
      Position: position,
      Email: email,
      PhoneNumber: phone,
      subPackageIds: selectedSubPackages.map((subpackage) => subpackage.id),
      locale: i18n.language
    })
      .then((response) => {
        showSuccess(t('formSubmittedSuccessfully'));
        clearForm();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
  };

  const clearForm = () => {
    setBusinessName('');
    setName("");
    setPosition('');
    setEmail("");
    setPhone('');
    setSelectedSubPackages([]);
  };

  return (
    <>
      <Helmet>
        <meta title="Request an Estimation  at Gogel GmbH"/>
        <meta name="description"
              content="Depending on your service request pick the best services to unlock your full potential."/>
        <meta name="pagesummary"
              content="By analysing data and adjusting our course, we ensure to always evolve your strategies to keep your business in the spotlight and drawing in new customers."/>
        <meta name="keywords"
              content="digital agency austria, Digital Marketing Spezialist, digital branding experience, B2B Content Marketing, SEO Agency Vienna, Digital marketing agency in Vienna,"/>
      </Helmet>
      <div ref={orderPageRef} className='fixed'/>
      <Header/>
      <div className='px-3 lg:px-0'>
        <VideoHeader video={data}/>
        <div className="w-full h-full md:py-10 lg:py-6 items-center self-center justify-center">
          <div
            className="flex lg:px-37 pt-5 pb-20 3xl:mx-auto max-w-[1600px] flex-col items-center self-center justify-center">
            <div
              className="font-semibold text-left tracking-wide text-2xl md:text-5xl lg:text-[3rem] text-opacity-90 uppercase flicker w-full">
              {t('requestCostEstimation')}
            </div>
            <div
              className="sm:px-0 px-5 pt-2 grid grid-cols-1 items-stretch justify-center w-full">
              <div className="flex-1">
                <OrderForm businessName={businessName}
                           setBusinessName={setBusinessName}
                           name={name}
                           setName={setName}
                           position={position}
                           setPosition={setPosition}
                           email={email}
                           setEmail={setEmail}
                           phone={phone}
                           setPhone={setPhone}
                           getError={getError}
                           clearError={clearError}/>
              </div>
              <div className="flex-1 pt-7">
                <Packages selectedSubPackages={selectedSubPackages}
                          setSelectedSubPackages={setSelectedSubPackages}
                          clearError={clearError}
                />
                {getError('packages') && <div
                  className={`text-xs text-red-500 pt-2 text-left ${getError('packages') ? '' : 'pb-4'}`}>{getError('packages') ? getError('packages') : ''}</div>}
              </div>
              <div className='flex-1 pt-7'>
                <div
                  className="font-semibold text-left pt-12 tracking-wide text-xl md:text-2xl lg:text-3xl text-opacity-90 flicker w-full">
                  {t('totalSummary')} :
                </div>
                <div
                  className="font-light text-left lg:pl-1.5 pt-0.5 pb-3 text-xs opacity-70 w-full">
                  *{t('vatExcluded')}
                </div>
                <div className="flex justify-between flex-col lg:w-120">
                  <CInput
                    border={''}
                    padding='pl-2 pr-5 py-1.5 text-right'
                    placeholder={'---- €'}
                    id="total"
                    value={`${total ? total : '----'} €`}
                    disabled={true}
                    className="flex-1 border"
                  />
                </div>
                <div ref={sectionRef} className="pt-3 lg:pt-6 flex justify-start">
                  <button className='my-6 lg:self-center py-2.5 package-button' onClick={onSubmit}>
                                        <span
                                          className='hover:animate-bounce font-sans button-text text-[#D32030] uppercase font-semibold'>{t('submit')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer sectionRef={sectionRef}/>
    </>
  );
}

export default OrderPage;