import React, {useEffect, useRef, useState} from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import useMouseCursor from "../hooks/useMouseCursor";
import Project from "@components/Projects/Project";
import CMSAPI from "@utils/plugins/CMSAPI";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";

const ProjectPage = () => {
  const { i18n } = useTranslation();
  const { slug } = useParams();
  const history = useHistory();

  const projectRef = useRef(null);
  const sectionRef = useRef(null);
  useMouseCursor(projectRef, sectionRef, 'cursor-white');

  const [data, setData] = useState([]);

  useEffect(async () => {
    const language =
      i18n.language === 'al'
        ? 'sq'
        : i18n.language === 'de'
          ? 'de-AT'
          : i18n.language;
    CMSAPI.get(`/projects?locale=${language}&populate=deep&filters[slug]=${slug}`).then(response => {
      if (response.data.data.length === 0){
        history.push('/projects');
        return
      }
      setData(response.data.data[0]?.attributes)
    })
  }, [i18n.language, slug]);

  return (
    <>
      <div ref={projectRef} className='fixed'/>
      <Header/>
      <Project data={data}/>
      <Footer sectionRef={sectionRef}/>
    </>
  );
};

export default ProjectPage;