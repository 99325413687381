import React from "react";
import {useTranslation} from "react-i18next";
import CInput from "@core/inputs/Input";
import {numbersOnly} from "@utils/helpers";

const OrderForm = ({
                       businessName,
                       setBusinessName,
                       name,
                       setName,
                       position,
                       setPosition,
                       email,
                       setEmail,
                       phone,
                       setPhone,
                       clearError,
                       getError
                   }) => {
    const {t} = useTranslation();

    return (
        <div className="flex flex-col">
            <div
                className="font-semibold text-left pt-12 pb-3 tracking-wide text-xl md:text-2xl lg:text-3xl text-opacity-90 flicker w-full">
                {t('yourInfo')} :
            </div>
            <div className="flex justify-between flex-col">
                <CInput
                    placeholder={t("businessName")}
                    id="businessName"
                    value={businessName}
                    handleInputChange={(value) =>
                        clearError("businessName", value, setBusinessName)
                    }
                    error={getError("businessName")}
                    className="flex-1"
                />
                <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-x-24'>
                    <CInput
                        placeholder={t("yourName")}
                        id="yourName"
                        value={name}
                        handleInputChange={(value) =>
                            clearError("name", value, setName)
                        }
                        error={getError("name")}
                        className="flex-1 pt-5 lg:pt-8"
                    />
                    <CInput
                        placeholder={t("yourPosition")}
                        id="yourPosition"
                        value={position}
                        handleInputChange={(value) =>
                            clearError("position", value, setPosition)
                        }
                        error={getError("position")}
                        className="flex-1 pt-5 lg:pt-8"
                    />
                    <CInput
                        placeholder={'E-Mail'}
                        id="email"
                        value={email}
                        handleInputChange={(value) =>
                            clearError("email", value, setEmail)
                        }
                        error={getError("email")}
                        className="flex-1 pt-5 lg:pt-8"
                    />
                    <CInput
                        placeholder={t("phone")}
                        id="phone"
                        value={phone}
                        handleInputChange={(value) =>
                            clearError("phone", value, setPhone(numbersOnly(value)))
                        }
                        error={getError("phone")}
                        className="flex-1 pt-5 lg:pt-8"
                    />
                </div>
            </div>
        </div>
    );
};

export default OrderForm;
