import React, {useEffect, useState} from 'react';
import CMSAPI from "@utils/plugins/CMSAPI";
import {useTranslation} from "react-i18next";
import RedCheckbox from '@assets/svg/redCheckbox.svg';
import ChevronDown from '@assets/svg/chevronDown.svg';

const Checkbox = ({id, checked, disabled = false, imageUrl}) => {
    return (
        <div className="inline-flex items-center">
            <label className="relative flex items-center p-2 rounded-full cursor-pointer" htmlFor={id}>
                <input
                    disabled={disabled}
                    type="checkbox"
                    className="before:content[''] peer relative h-7 w-7 cursor-pointer appearance-none rounded-md border border-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-brand-normal checked:bg-brand-normal checked:before:bg-brand-normal hover:before:opacity-10"
                    id={id}
                    checked={checked}
                />
                <span
                    className={`absolute transition-opacity opacity-${checked ? '100' : '0'} pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4`}>
                    <img
                        src={imageUrl}
                        alt="Checkbox Image"
                        className="h-6 w-6"
                    />
                </span>
            </label>
        </div>
    );
};

const CheckboxItem = ({ label, selected, onClick, price }) => {
  return (
    <div
      className={`flex text-[#666666] font-semibold py-4 cursor-pointer transition-all duration-300 items-center`}
      onClick={onClick}>
      <Checkbox checked={selected} imageUrl={RedCheckbox} />
      <div className='grid grid-cols-6 items-center w-full'>
        <span className='col-span-5 w-11/12 ml-3 flex-1 font-normal text-left tracking-wide text-base lg:text-lg'>{label}</span>
        <span className='col-span-1 text-left tracking-wide text-base lg:text-lg text-gray-800 font-semibold'>{price}€</span>
      </div>
    </div>
  );
};

const PackageItem = ({name, onClick, selectedSubPackages, subPackages, selectedPackage, id}) => {
    const isSelected = subPackages?.some(subPackage => selectedSubPackages.find(sp => sp.id === subPackage.id));

    return (
        <div
            className='flex py-4 cursor-pointer transition-all duration-300 items-center'
            onClick={onClick}>
            <Checkbox checked={isSelected} imageUrl={ChevronDown}/>
            <span
                className={`ml-3 flex-1 text-left tracking-wide text-base lg:text-lg w-full transition transform duration-300 ${selectedPackage === id ? 'text-gray-800 font-bold' : 'text-[#666666] font-normal'}`}>{name}</span>
        </div>
    );
};

const Packages = ({selectedSubPackages, setSelectedSubPackages, clearError}) => {
    const {i18n, t} = useTranslation();

    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);

    useEffect(() => {
        setSelectedSubPackages([]);
        const language =
            i18n.language === 'al'
                ? 'sq'
                : i18n.language === 'de'
                    ? 'de-AT'
                    : i18n.language;
        CMSAPI.get(`/packages?locale=${language}&populate=deep`).then(response => {
            setPackages(response?.data?.data)
        })
    }, [i18n.language]);

    const handlePackageClick = (packageId) => {
        setSelectedPackage(packageId);
    };

    const handleSubPackageClick = (subPackage) => {
        if (selectedSubPackages.find(sp => sp.id === subPackage.id)) {
            setSelectedSubPackages(selectedSubPackages.filter(sp => sp.id !== subPackage.id));
        } else {
            setSelectedSubPackages([...selectedSubPackages, subPackage]);
        }
        clearError('packages');
    };


    return (
        <div>
            <div
                className="font-semibold text-left pt-12 pb-3 tracking-wide text-xl md:text-2xl lg:text-3xl text-opacity-90 flicker w-full">
                {t('whatServiceDoYouRequire')} :
            </div>
            <div className="flex flex-col justify-between lg:grid lg:grid-cols-2 lg:gap-24">
                <div>
                    {packages.map((attribute) => (
                        <PackageItem
                            name={attribute.attributes.name}
                            onClick={() => handlePackageClick(attribute.id)}
                            subPackages={attribute.attributes.sub_packages.data}
                            selectedSubPackages={selectedSubPackages}
                            key={attribute.id}
                            id={attribute.id}
                            selectedPackage={selectedPackage}
                        />
                    ))}
                </div>
                <div>
                    {selectedPackage && packages.find(p => p.id === selectedPackage)?.attributes?.sub_packages?.data.length ? (
                        <div className="border border-gray-[#666666] px-5">
                            {packages.find(p => p.id === selectedPackage)?.attributes?.sub_packages?.data.map((option) => (
                                <CheckboxItem
                                  price={option?.attributes?.price}
                                  key={`option-${option.id}`}
                                  label={option.attributes.name}
                                  selected={selectedSubPackages.find(sp => sp.id === option.id)}
                                  onClick={() => handleSubPackageClick(option)}
                                />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Packages;
