import React, {useEffect, useRef, useState} from "react";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Services from "@components/Services/Service";
import useMouseCursor from "../hooks/useMouseCursor";
import CMSAPI from "@utils/plugins/CMSAPI";
import {useTranslation} from "react-i18next";
import ServiceHeader from '@components//Services/Header'
import {Helmet} from "react-helmet";

const ServicesPage = () => {
  const {i18n} = useTranslation();

  const serviceRef = useRef(null);
  const sectionRef = useRef(null);
  useMouseCursor(serviceRef, sectionRef, 'cursor-white')

  const [services, setServices] = useState([]);

  useEffect(async () => {
    const language =
      i18n.language === 'al'
        ? 'sq'
        : i18n.language === 'de'
          ? 'de-AT'
          : i18n.language;
    CMSAPI.get(`/our-services?locale=${language}&populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setServices(attributes)
    })
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <meta title="Digital Services Gogel GmbH"/>
        <meta name="description"
              content="Partner with us today and unlock the full potential of digital marketing advertising and strategy for your business."/>
        <meta name="pagesummary" content="Our team of experienced brand strategists, designers, and creatives collaborate closely with you to develop a comprehensive brand strategy that sets you apart from the competition and drives meaningful engagement.
"/>
        <meta name="keywords"
              content="digital agency austria, Digital Marketing Spezialist, digital branding experience, B2B Content Marketing, SEO Agency Vienna, Digital marketing agency in Vienna,"/>
      </Helmet>
      <div ref={serviceRef} className='fixed'/>
      <Header/>
      <ServiceHeader title={services?.title} description={services?.description}/>
      <Services services={services?.services}/>
      {services && <Footer sectionRef={sectionRef}/>}
    </>
  );
}

export default ServicesPage;
