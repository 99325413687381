import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import {isArrayEmpty} from "@utils/helpers";
const Service = ({title, description, icon}) => {

  return (
    <div
      className='py-6 px-2 md:p-6 lg:p-10 border border-[#000002] border-opacity-20 rounded-md align-middle items-center shadow-sm'>
      <div className="grid grid-cols-1 justify-between">
        <div className='flex flex-row justify-between my-5 lg:my-0'>
          <div className="font-sans font-semibold text-base lg:text-xl xl:w-1/2 2xl:text-3xl" data-aos="fade-right"
               data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="300" data-aos-once="true">{title}</div>
          <img src={icon} className='icon' alt="icon" data-aos="fade-right" data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"/>
        </div>
        <div data-aos="fade-left" data-aos-offset="50"
             data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"
             className="text-gray-700 flex font-sans leading-5 justify-end align-middle mt-3 self-center items-center font-light md:mt-24 w-full text-xs md:text-sm">
          {description}
        </div>
      </div>
    </div>
  )
};

const FullService = ({title, description1, description2, icon}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div
      className='my-8 py-6 px-2 md:p-6 lg:p-10 border border-[#000002] border-opacity-20 rounded-md align-middle items-center shadow-sm'>
      <div className="grid grid-cols-1 justify-between">
        <div className='flex flex-row justify-between my-5 lg:my-0'>
          <div className="font-sans font-semibold text-base lg:text-xl xl:w-1/4 2xl:text-3xl" data-aos="fade-right"
               data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="300" data-aos-once="true">{title}</div>
          <img src={icon} className='icon' alt="icon" data-aos="fade-right" data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"/>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
          <div data-aos="fade-left" data-aos-offset="50"
             data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"
             className="w-full md:w-3/5 text-gray-700 flex font-sans leading-5 justify-end align-middle mt-3 self-center items-center font-light md:mt-24 text-xs md:text-sm">
          {description1}
          </div>
          <div data-aos="fade-left" data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"
               className="text-gray-700 flex font-sans leading-5 justify-end align-middle mt-3 self-center items-center font-light md:mt-24 w-full text-xs md:text-sm">
            {description2}
          </div>
        </div>
      </div>
    </div>
  )
};
const Services = ({ services = [] }) => {
  return (
    <div className="w-full h-full">
      <div className='pt-14 pb-6 lg:pb-20 3xl:mx-auto max-w-[1700px] flex-col mx-5 lg:mx-10 xl:mx-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {!isArrayEmpty(services) && services.map((service) => {
            const shouldRenderFullService = service?.description2 !== null;

            return (
              <React.Fragment key={service?.title}>
                {shouldRenderFullService ? (
                  <div className="lg:col-span-2">
                    <FullService
                      title={service?.title}
                      description1={service?.description1}
                      description2={service?.description2}
                      icon={service?.image?.data?.attributes?.url}
                  />
                  </div>
                ) : (
                    <Service
                      title={service?.title}
                      description={service?.description1}
                      icon={service?.image?.data?.attributes?.url}
                    />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Services;

