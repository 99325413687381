import React, { useEffect, useState } from 'react';
import logo from '@assets/svg/logo.svg';
import whiteLogo from '@assets/svg/whiteLogo.svg';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import LanguageSelector from "@core/dropdowns/LanguageSelector";
import {useTranslation} from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const [classes, setClasses] = useState('');
  const textClasses = "relative before:content-[''] before:absolute before:block before:w-full before:h-[2px] before:bottom-0 before:left-0 before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300"
  const [extraClasses, setExtraClasses] = useState('')
  const [isMounted, setIsMounted] = useState(true);

  const changeBackground = () => {
    if (isMounted) {
      const scrollPosition = window.scrollY;
      const newClasses = scrollPosition > 80 ? 'backdrop-blur bg-[#000005] bg-opacity-30 text-white' : 'backdrop-blur bg-[#000005] bg-opacity-30 md:bg-transparent text-white md:text-gray-900';
      const newTextClasses = scrollPosition > 80 ? 'before:bg-white' : 'before:bg-gray-900';
      setClasses(newClasses);
      setExtraClasses(newTextClasses);
    }
  }

  useEffect(() => {
    setIsMounted(true)
    changeBackground()
    window.addEventListener("scroll", changeBackground)

    return () => setIsMounted(false)
  })

  return (
    <nav className={`${classes} transition duration-300 fixed w-full justify-around z-40 md:py-2`}>
      <div className="mx-3 px-2 sm:px-6 lg:px-8 hidden lg:block">
        <div className="relative flex items-center justify-between h-14">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <Link to='/'>
              <img className="h-10" src={window.scrollY > 80 ? whiteLogo : logo} alt="Logo"/>
            </Link>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="ml-3 relative">
              <div className="flex">
                <Link
                  className={`${location.pathname === '/about-us' && 'font-bold'} font-inter text-base leading-5 mr-10 ${textClasses} ${extraClasses}`}
                  to='/about-us'
                >
                  {t('aboutUs')}
                </Link>
                <Link
                  className={`${location.pathname === '/services' && 'font-bold'} font-inter text-base leading-5 mr-10 ${textClasses} ${extraClasses}`}
                  to='/services'
                >
                  {t('services')}
                </Link>
                <Link
                  className={`${location.pathname === '/projects' && 'font-bold'} font-inter text-base leading-5 mr-10 ${textClasses} ${extraClasses}`}
                  to='/projects'
                >
                  {t('projects')}
                </Link>
                <Link
                  className={`${location.pathname === '/clients' && 'font-bold'} font-inter text-base leading-5 mr-10 ${textClasses} ${extraClasses}`}
                  to='/clients'
                >
                  {t('clients')}
                </Link>
                <Link
                  className={`${location.pathname === '/contact' && 'font-bold'} font-inter text-base leading-5 mr-10 ${textClasses} ${extraClasses}`}
                  to='/contact'
                >
                  {t('contact')}
                </Link>
                <Link
                    className={`${location.pathname === '/order' && 'font-bold'} font-inter text-base leading-5 mr-10 ${textClasses} ${extraClasses}`}
                    to='/cost-estimation'
                >
                  {t('placeOrder')}
                </Link>
              </div>
            </div>
            <LanguageSelector/>
          </div>
        </div>
      </div>

      <div
        className={`lg:hidden ${
          isOpen ? "main-nav-open" : null
        }`}
      >
        <div className="flex place-content-between py-4 pl-4 pr-8">
          <Link className="mr-3" to="/">
            <img className="h-8" src={whiteLogo} alt="Logo"/>
          </Link>
          <button
            id="mobile-menu"
            className="focus:outline-none mt-4"
            onClick={() => setIsOpen(!isOpen)}
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <div className={`${isOpen ? 'open-burger' : 'burger'}`}/>
          </button>
        </div>
        <Transition
          show={isOpen}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="lg:hidden" id="mobile-menu">
            <div className="flex flex-col pl-7 py-4 space-y-2 justify-center mx-auto">
              <Link
                className={`${location.pathname === '/about-us' && 'text-[#0991DB]'} pt-10 font-inter text-base leading-5 mr-10 font-normal ${textClasses} ${extraClasses} main-nav-text-open`}
                to='/about-us'
              >
                {t('aboutUs')}
              </Link>
              <Link
                className={`${location.pathname === '/services' && 'text-[#0991DB]'} pt-10 font-inter text-base leading-5 mr-10 font-normal ${textClasses} ${extraClasses} main-nav-text-open`}
                to='/services'
              >
                {t('services')}
              </Link>
              <Link
                className={`${location.pathname === '/projects' && 'text-[#0991DB]'} pt-10 font-inter text-base leading-5 mr-10 font-normal ${textClasses} ${extraClasses} main-nav-text-open`}
                to='/projects'
              >
                {t('projects')}
              </Link>
              <Link
                className={`${location.pathname === '/clients' && 'text-[#0991DB]'} pt-10 font-inter text-base leading-5 mr-10 font-normal ${textClasses} ${extraClasses} main-nav-text-open`}
                to='/clients'
              >
                {t('clients')}
              </Link>
              <Link
                className={`${location.pathname === '/contact' && 'text-[#0991DB]'} pt-10 font-inter text-base leading-5 mr-10 font-normal ${textClasses} ${extraClasses} main-nav-text-open`}
                to='/contact'
              >
                {t('contact')}
              </Link>
              <Link
                  className={`${location.pathname === '/order' && 'text-[#0991DB]'} pt-10 font-inter text-base leading-5 mr-10 font-normal ${textClasses} ${extraClasses} main-nav-text-open`}
                  to='/cost-estimation'
              >
                {t('placeOrder')}
              </Link>
              <div className='pt-10 font-inter text-base leading-5 mr-10 font-normal main-nav-text-open mx-auto'>
                <LanguageSelector/>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </nav>
  );
}

export default Header;
