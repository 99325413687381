import React from 'react';
import ContactDetails from "@components/Contact/ContactDetails";
import ContactForm from "@components/Contact/ContactForm";
import {useTranslation} from "react-i18next";

const Contact = ({ buttonRef}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full md:py-10 lg:py-6 items-center self-center justify-center">
      <div
        className="flex pt-20 mx-3 3xl:mx-auto max-w-[1500px] flex-col items-center self-center justify-center">
        <div className="flex flex-col justify-center text-center items-center self-center relative lg:w-3/5">
          <div
            className="font-semibold tracking-wide text-3xl md:text-6xl lg:text-[4rem] text-opacity-90 uppercase flicker w-full px-5 lg:px-10 xl:px-0">
            {t('letsTalk')}
          </div>
          <div className="sm:px-0 px-5 pt-2 grid grid-cols-1 lg:grid-cols-2 items-stretch justify-center w-full">
            <div className="flex-1">
              <ContactForm buttonRef={buttonRef}/>
            </div>
            <div className="flex-1">
              <ContactDetails />
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
export default Contact;

