import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const Header = ({title, description}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full md:py-10 lg:py-6 items-center self-center justify-center">
      <div
        className="flex pt-20 mx-3 3xl:mx-auto max-w-[1500px] flex-col items-center self-center justify-center">
        <div
          className="grid grid-cols-1 lg:grid-cols-2 justify-center text-center self-center relative px-3">
          <div data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
               data-aos-once="true"
               className="w-full xl:w-4/5 font-semibold text-center lg:text-left lg:ml-10 xl:ml-14 uppercase tracking-wide text-3xl md:text-5xl xl:text-6xl text-opacity-90 lg:mt-10">
            {title ? title : <SkeletonLoader count={1} /> }
          </div>
          <div
            className='flex flex-col mt-10 font-thin text-sm md:text-base xl:text-xl leading-tight lg:text-justify lg:w-4/5'
            data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
            data-aos-once="true">
            {description ? <span>{description}</span> : <SkeletonLoader count={5}/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;




