import Axios from 'axios'
import {CMS_URL} from "@constants/index";

const CMSAPI = Axios.create({
  baseURL: CMS_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default CMSAPI