import React, {useEffect, useRef, useState} from "react";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Clients from "@components/Clients/Clients";
import useMouseCursor from "../hooks/useMouseCursor";
import CMSAPI from "@utils/plugins/CMSAPI";
import {useTranslation} from "react-i18next";
import ClientsHeader from "@components/Clients/Header";
import {Helmet} from "react-helmet";

const ClientsPage = () => {
  const {i18n} = useTranslation();

  const clientsRef = useRef(null);
  const sectionRef = useRef(null);
  useMouseCursor(clientsRef, sectionRef, 'cursor-white');

  const [data, setData] = useState([])

  useEffect(async () => {
    const language =
      i18n.language === 'al'
        ? 'sq'
        : i18n.language === 'de'
          ? 'de-AT'
          : i18n.language;
    CMSAPI.get(`/our-clients?locale=${language}&populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setData(attributes)
    })
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <meta title="Clients of Gogel GmbH"/>
        <meta name="description" content="Check below our main clients that believed in our approach."/>
        <meta name="pagesummary" content="Portfolio of clients at Gogel GmbH"/>
        <meta name="keywords"
              content="digital agency austria, Digital Marketing Spezialist, digital branding experience, B2B Content Marketing, SEO Agency Vienna, Digital marketing agency in Vienna,"/>
      </Helmet>
      <div className='fixed' ref={clientsRef}/>
      <Header/>
      <ClientsHeader title={data?.title} description={data?.description}/>
      <Clients clients={data?.clients}/>
      {data && <Footer sectionRef={sectionRef}/>}
    </>
  );
}

export default ClientsPage;