import React, {useEffect} from 'react';
import '@assets/css/style.css';
import ReactPlayer from 'react-player';
import AOS from "aos";
import {useTranslation} from "react-i18next";

const VideoHeader = ({video}) => {
    const {t} = useTranslation();

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    return (
        <div className="w-full h-full items-center self-center justify-center relative">
            <div className="flex flex-col items-center self-center justify-center">
                <div
                    style={{
                        width: '100vw',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ReactPlayer
                        playsinline
                        scrollableAncestor='window'
                        url={video}
                        playing={true}
                        loop={false}
                        width="100%"
                        height="100%"
                        controls={false}
                        muted={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default VideoHeader;
