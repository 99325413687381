const contactUsValidator = {
  name: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'errors.notEmpty',
    },
    email: {
      message: 'errors.incorrectEmailFormat'
    }
  },
  work: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
  idea: {
    presence: {
      allowEmpty: false,
      message: "errors.notEmpty",
    }
  },
};

export default contactUsValidator;
