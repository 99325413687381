import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import SkeletonLoader from "@core/loaders/SkeletonLoader";
import {useTranslation} from "react-i18next";

const AboutUs = ({title, desccription1, description2}) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full py-10 lg:py-6 items-center self-center justify-center">
      <div
        className="flex pt-10 md:pt-20 mx-3 3xl:mx-auto max-w-[1500px] flex-col items-center self-center justify-center">
        <div
          className="grid grid-cols-1 lg:grid-cols-2 justify-center text-center self-center relative px-3">
          <div data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
               data-aos-once="true"
               className="w-full xl:w-4/5 font-semibold text-center lg:text-left lg:ml-20 xl:ml-40 uppercase tracking-wide text-4xl md:text-5xl xl:text-6xl text-opacity-90 lg:mt-10">
            {title ? title : <SkeletonLoader count={1}/> }
            <div className='flex flex-col gap-2 font-light text-left text-sm mt-8'>
              <div><span className='font-semibold'>{t('companyName')}:</span> Gogel GmbH</div>
              <div><span className='font-semibold'>{t('address')}:</span> Marxergasse 7/8, 1030 Vienna, Austria</div>
              <div><span className='font-semibold'>{t('taxIdentificationNumber')}:</span> ATU77568348</div>
              <div>
                <span className='font-semibold'>{t('telephone')}:</span>
                <a href="tel:+4368181575656" className='hover:cursor-pointer hover:underline'> +4368181575656</a>
              </div>
              <div><span className='font-semibold'>{t('contactPerson')}:</span> Gjergji Xhoxhaj</div>
            </div>
          </div>
          <div
            className='flex flex-col mt-10 font-thin text-sm md:text-base xl:text-xl leading-tight lg:text-justify lg:w-4/5'
            data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
            data-aos-once="true">
            {desccription1 ? <span>{desccription1}</span> : <SkeletonLoader count={3}/> }
            <br/>
            {description2 ? <span>{description2}</span> : <SkeletonLoader count={5} /> }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;




