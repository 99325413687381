import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { FaChevronDown } from "react-icons/fa";

export const supportedLanguages = ['de', 'en']
const LanguageSelector = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const {i18n} = useTranslation();

  const textClasses = "relative before:content-[''] before:absolute before:block before:w-full before:h-[2px] before:bottom-0 before:left-0 before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300"
  const [extraClasses, setExtraClasses] = useState('')
  const [isMounted, setIsMounted] = useState(true);

  const changeBackground = () => {
    if (isMounted) {
      const newTextClasses = window.scrollY <= 60 ? "bg-white before:bg-black text-black" : 'bg-black before:bg-white text-white';
      setExtraClasses(newTextClasses)
    }
  }

  useEffect(() => {
    setIsMounted(true)
    changeBackground()
    window.addEventListener("scroll", changeBackground)

    return () => setIsMounted(false)
  })

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => localStorage.setItem('GogelWebLang', lang));
  }

  const onClickOutsideListener = useCallback(() => {
    setShowDropdown(false);
    document.removeEventListener('click', onClickOutsideListener);
  }, []);

  const addOnClickOutsideListener = useCallback(() => {
    document.addEventListener('click', onClickOutsideListener);
  }, [onClickOutsideListener]);

  const languageOptions = () => supportedLanguages.map((lang) => (
    <div
      id={lang}
      key={lang}
      className={`flex justify-center px-1 py-2 bg-transparent ${textClasses} ${extraClasses} ${
        i18n.language === lang ? `${window.scrollY <= 60 ? 'bg-gray-300' : 'bg-gray-700'}` : `${window.scrollY <= 60 ? 'hover:bg-gray-200 cursor-pointer' : 'hover:bg-gray-800 cursor-pointer'}`
      }`}
      onClick={() => switchLanguage(lang)}>
      <div className="text-sm font-medium uppercase">
        {lang}
      </div>
    </div>
  ));

  useEffect(() => {
    if (showDropdown) addOnClickOutsideListener();
  }, [addOnClickOutsideListener, showDropdown]);

  return (
    <div
      className="relative w-11">
      <div
        className={`w-full focus:outline-none py-1 flex items-center cursor-pointer ${showDropdown ? 'font-normal' : 'font-bold'} hover:font-bold transition-all duration-300`}
        onClick={() => {
          setShowDropdown((prevState) => !prevState);
        }}>
        <div className="uppercase text-sm font-medium">
          {i18n.language}
        </div>
        <FaChevronDown className="h-3 ml-1" />
      </div>
      <div
        className={`${
          showDropdown ? 'max-h-56' : 'max-h-0'
        } transition-all duration-300 ease-in w-full absolute z-50 ${window.scrollY <= 60 ? "bg-white text-black" : 'bg-black text-white'} bg-opacity-90 mt-0.5 rounded-md shadow-2xl overflow-hidden`}>
        {languageOptions()}
      </div>
    </div>
  )
}

export default LanguageSelector;
