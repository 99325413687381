import React, { useState } from "react";
import {showError, showSuccess} from "@utils/helpers";
import { useTranslation } from "react-i18next";
import useFormErrors from "../../hooks/useFormErrors";
import contactUsValidator from "@utils/validators/ContactUsValidator";
import CInput from "@core/inputs/Input";
import CMSAPI from "@utils/plugins/CMSAPI";

const ContactForm = ({buttonRef}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [work, setWork] = useState("");
  const [idea, setIdea] = useState("");

  const { t } = useTranslation();
  const { clearError, getError, validateErrors } = useFormErrors();

  const sendMessage = () => {
    const errors = validateErrors(
      { name, email, work, idea },
      contactUsValidator
    );
    if (errors) {
      return;
    }
    CMSAPI.post("/contacts", {data: { fullName: name, email, workType: work, message:idea }})
      .then((response) => {
        showSuccess(t('formSubmittedSuccessfully'));
        clearForm();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setWork("");
    setIdea("");
  };

  return (
    <div className="flex flex-col pt-7">
      <div className="flex justify-between flex-col">
        <CInput
          placeholder={t("yourName")}
          id="yourName"
          value={name}
          handleInputChange={(value) =>
            clearError("name", value, setName)
          }
          error={getError("name")}
          className="flex-1"
        />
        <CInput
          placeholder={t("yourEmail")}
          id="yourEmail"
          value={email}
          handleInputChange={(value) =>
            clearError("email", value, setEmail)
          }
          error={getError("email")}
          className="flex-1 pt-5 lg:pt-8"
        />
        <CInput
          id="work"
          placeholder={t("whatTypeOfWorkAreYouInterestedIn")}
          value={work}
          handleInputChange={(value) => clearError("work", value, setWork)}
          error={getError("work")}
          className="flex-1 pt-5 lg:pt-8"
        />
        <CInput
          id="idea"
          value={idea}
          placeholder={t('tellUsYourIdea')}
          error={getError("idea")}
          handleInputChange={(value) => clearError("idea", value, setIdea)}
          className="flex-1 pt-5 lg:pt-8"
        />
      </div>
      <div ref={buttonRef} className="pt-3 lg:pt-6 flex justify-start">
        <button className='my-6 lg:self-center py-2.5 contact-button' onClick={sendMessage}>
          <span className='hover:animate-bounce font-sans button-text'>{t('send')}</span>
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
