import React, {useEffect} from 'react';
import '@assets/css/style.css';
import AOS from 'aos';
import 'aos/dist/aos.css'
import {isArrayEmpty} from "@utils/helpers";

const Staff = ({team, fullName, position}) => {
  return (
    <div className='overflow-hidden bg-cover bg-no-repeat group flex flex-col  text-center'>
      <img src={team} alt="team" className='relative rounded-xs'/>
        <div className='pt-3.5 w-full text-xl font-sans tracking-wider font-semibold'>
          {fullName}
        </div>
        <div className='pt-0.5 w-full text-base font-inter font-light'>
          {position}
      </div>
    </div>
  );
};

const Team = ({sectionRef, team = []}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])


  return (
    <div className='relative w-full h-full max-width-[1700px]'>
      <div className="flex w-full justify-center items-center lg:pt-14">
        <div className="flex w-full flex-col items-center xl:w-4/5 [@media(min-width:1510px)]:w-3/4 2xl:my-10">
          <div className="team-wrapper" ref={sectionRef}>
            <section
              className='hidden md:grid container team-container md:px-10 lg:px-30 xl:px-44 3xl:px-76'>
              {!isArrayEmpty(team) && team.map((teamMember, index) => {
                return <Staff team={teamMember?.image?.data?.attributes?.url}
                              fullName={teamMember?.fullName}
                              position={teamMember?.position}/>
              })}
            </section>
            <section
              className='grid grid-cols-1 px-10 md:hidden gap-8 mb-8'>
              {!isArrayEmpty(team) && team.map((teamMember, index) => {
                return <Staff team={teamMember?.image?.data?.attributes?.url}
                              fullName={teamMember?.fullName}
                              position={teamMember?.position}/>
              })}
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team;
