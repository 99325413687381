const packageValidator = {
    businessName: {
        presence: {
            allowEmpty: false,
            message: "errors.notEmpty",
        }
    },
    name: {
        presence: {
            allowEmpty: false,
            message: "errors.notEmpty",
        }
    },
    position: {
        presence: {
            allowEmpty: false,
            message: "errors.notEmpty",
        }
    },
    email: {
        presence: {
            allowEmpty: false,
            message: 'errors.notEmpty',
        },
        email: {
            message: 'errors.incorrectEmailFormat'
        }
    },
    phone: {
        presence: {
            allowEmpty: false,
            message: "errors.notEmpty",
        }
    },
    packages: {
        presence: {
            allowEmpty: false,
            message: "errors.notEmpty",
        },
        length: {
            minimum: 1,
            message: "errors.notEmpty",
        }
    }
};

export default packageValidator;
