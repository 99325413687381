import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { isArrayEmpty } from '@utils/helpers';
import {useHistory} from "react-router-dom";

const Client = ({ img, url }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onClick={() => window.open(url, "_blank")}
      className={`hover:cursor-pointer border-0.5 border-[#000005] border-opacity-20 align-middle items-center relative ${
        isHovered ? 'hovered' : ''
      }`}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <img
        src={img}
        alt="Client Logo"
        className={`inset-0 w-full h-full ${isHovered ? 'desaturate' : ''}`}
        style={{ objectFit: 'contain' }}
      />
    </div>
  );
};

const Clients = ({ clients = [] }) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="w-full h-full" onClick={() => window.open()}>
      <div className="flex py-10 lg:py-20 3xl:mx-auto max-w-[1700px] flex-col mx-5 lg:mx-10 xl:ml-20">
        <div className='grid grid-cols-2 lg:grid-cols-4 xl:pr-20'>
          {!isArrayEmpty(clients) &&
            clients.map((client, index) => {
              return <Client key={index} img={client?.image?.data?.attributes?.url} url={client?.redirectUrl}/>;
            })}
        </div>
      </div>
    </div>
  );
};

export default Clients;
